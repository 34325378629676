import React, { useState, useEffect } from "react";
import FD from "./feesdirectory.module.scss";
import feesIcon from "../../assets/icons/fees.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusicon.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftarrow.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Constants } from "../../constants";
import qs from "qs";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import swal from "sweetalert";

import Ad from "./addstudent.module.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function FeesCollectionMethods() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.value);
  const [spin, setSpin] = useState(true);
  const [schoolData, setSchoolData] = useState({});

  const [error, setError] = useState("");

  const [templates, settemplates] = useState([]);
  const [open, setOpen] = useState(false);

  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openQRModal, setOpenQRModal] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLinkMOpen = () => setOpenLinkModal(true);
  const handleLinkMClose = () => setOpenLinkModal(false);

  const handleQRMOpen = () => setOpenQRModal(true);
  const handleQRMClose = () => setOpenQRModal(false);

  const [feesCollectionLink, setFeesCollectionLink] = useState("");
  const [feesCollectionQR, setFeesCollectionQR] = useState("");

  const [editopen, seteditOpen] = useState(false);
  const handleeditOpen = () => seteditOpen(true);
  const handleeditClose = () => {
    seteditOpen(false);
    setFeesItems([{ items: "", amount: "" }]);
    setTemplateName("");
    setTemplateCode("");
  };
  const [templateName, setTemplateName] = useState("");
  const [templateCode, setTemplateCode] = useState("");
  const [feesItems, setFeesItems] = useState([{ item_name: "", amount: "" }]);

  useEffect(() => {
    if (userDetails.school_code) {
      getDataGridData();
    }
  }, []);

  const getDataGridData = () => {
    setSpin(true);
    var data = qs.stringify({});

    var config = {
      method: "GET",
      url: `${Constants.api_url}/schools/find/${userDetails.school_code}`,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (result) {
        setSpin(false);
        console.log(result.data);
        setSchoolData(result.data);
        setFeesCollectionLink(result.data.fees_collection_link);
        setFeesCollectionQR(result.data.fees_collection_QR);
      })
      .catch(function (error) {
        setSpin(false);
        console.log(error.message);
      });
  };

  const validateFeesCollectionLink = () => {
    let formIsValid = true;
    setError("");
    if (
      feesCollectionLink === null ||
      feesCollectionLink === "" ||
      feesCollectionLink === " "
    ) {
      formIsValid = false;
      setError("Fees collection link cannot be empty!");
    }

    return formIsValid;
  };

  const validateFeesCollectionQR = () => {
    let formIsValid = true;
    setError("");
    if (
      feesCollectionQR === null ||
      feesCollectionQR === "" ||
      feesCollectionQR === " " ||
      typeof feesCollectionQR === "string"
    ) {
      formIsValid = false;
      setError("QR code image is required!");
    }
    return formIsValid;
  };

  const updateFeesCollectionLink = () => {
    let formIsValid = validateFeesCollectionLink();
    if (formIsValid) {
      axios
        .put(
          `${Constants.api_url}/schools/update/fees/collection/link?school_code=${userDetails.school_code}&fees_collection_link=${feesCollectionLink}`,
          {}
        )
        .then(function (result) {
          console.log(result.data);
          swal({
            title: "Success",
            text: "Successfully Updated",
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
          getDataGridData();
          handleClose();
        })
        .catch(function (error) {
          swal({
            title: "Warning!",
            text: "Please enter valid details",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
            }
          });
        });
    }
  };

  const updateFeesCollectionQR = () => {
    let formIsValid = validateFeesCollectionQR();
    if (formIsValid) {
      var formdata = new FormData();
      formdata.append("school_code", schoolData.school_code);

      formdata.append("fees_collection_QR", feesCollectionQR);

      axios
        .put(`${Constants.api_url}/schools/update/fees/collection/QR`, formdata)
        .then((response) => {
          console.log("result", response.data);
          setSpin(false);
          if (response.status === 200) {
            swal({
              title: "Success",
              text: response.data.message,
              icon: "success",
              dangerMode: false,
              closeOnClickOutside: false,
            }).then((willDelete) => {
              if (willDelete) {
                setSpin(false);
                setError("");
                setFeesCollectionQR("");
                getDataGridData();
              }
            });
          } else {
            swal({
              title: "Oops!",
              text: response.data.message,
              icon: "warning",
              dangerMode: false,
              closeOnClickOutside: false,
            }).then((willDelete) => {
              if (willDelete) {
                setError("");
                setFeesCollectionQR("");
                getDataGridData();
              }
            });
          }
        })
        .catch((error) => {
          setSpin(false);
          console.log("error", error);
          setSpin(false);
          swal({
            title: "Oops!",
            text: "Something went wrong",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setError("");
              setFeesCollectionQR("");
              getDataGridData();
            }
          });
        });
    }
  };

  const deleteFeesCollectionQR = () => {
    axios
      .delete(
        `${Constants.api_url}/schools/delete/fees/collection/QR?school_code=${schoolData.school_code}`
      )
      .then((response) => {
        console.log("result", response.data);
        setSpin(false);
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
              setError("");
              setFeesCollectionQR("");
              getDataGridData();
            }
          });
        } else {
          swal({
            title: "Oops!",
            text: response.data.message,
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setError("");
              setFeesCollectionQR("");
              getDataGridData();
            }
          });
        }
      })
      .catch((error) => {
        setSpin(false);
        console.log("error", error);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setError("");
            setFeesCollectionQR("");
            getDataGridData();
          }
        });
      });
  };

  const handleFeesItemChange = (index, key, value) => {
    const updatedFeesItems = [...feesItems];
    updatedFeesItems[index][key] = value;
    setFeesItems(updatedFeesItems);
  };

  const addFeesItem = () => {
    setFeesItems([...feesItems, { item_name: "", amount: "" }]);
  };
  const deleteFeesItem = (index) => {
    const updatedFeesItems = [...feesItems];
    updatedFeesItems.splice(index, 1);
    setFeesItems(updatedFeesItems);
  };
  const handleTemplate = (item) => {
    setTemplateName(item.template_name);
    setTemplateCode(item.template_code);
    setFeesItems(item.fees_items);
    handleeditOpen();
  };

  const handleFormSubmit = () => {
    const formData = {
      template_name: templateName,
      template_code: templateCode,
      fees_items: feesItems,
    };
    axios
      .post(
        `${Constants.api_url}/feestemplate/create-new?school_code=${userDetails.school_code}`,
        formData
      )
      .then(function (result) {
        console.log(result.data);
        swal({
          title: "Success",
          text: "Successfully added",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setFeesItems([{ items: "", amount: "" }]);
            setTemplateName("");
            setTemplateCode("");
          }
        });
        getDataGridData();
        handleClose();
      })
      .catch(function (error) {
        swal({
          title: "Warning!",
          text: "Please enter valid details",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const handleDelete = () => {
    axios
      .delete(
        `${Constants.api_url}/feestemplate/delete-template/${templateCode}`
      )
      .then(function (result) {
        swal({
          title: "Success",
          text: "Deleted Successfully",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
        getDataGridData();
        handleeditClose();
      })
      .catch(function (error) {
        console.error(error);
        swal({
          title: "Warning!",
          text: "Please enter valid details",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };

  const handleUpdate = () => {
    const formData = {
      template_name: templateName,
      template_code: templateCode,
      fees_items: feesItems,
    };

    axios
      .put(
        `${Constants.api_url}/feestemplate/update-template/${templateCode}`,
        formData
      )
      .then(function (result) {
        console.log(result.data);
        swal({
          title: "Success",
          text: "Successfully updated",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setFeesItems([{ items: "", amount: "" }]);
            setTemplateName("");
            setTemplateCode("");
            handleeditClose();
            getDataGridData();
          }
        });
      })
      .catch(function (error) {
        swal({
          title: "Warning!",
          text: "Please enter valid details",
          icon: "warning",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      });
  };
  return (
    <div className={FD.maincontainer}>
      <div className={FD.topbar}>
        <LeftArrow onClick={() => navigate(-1)} className={FD.backbutton} />
      </div>
      <Modal
        open={openLinkModal}
        onClose={handleLinkMClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "80vh",
              overflowY: "scroll",
            }}
          >
            <label>
              Fees Collection Link:
              <input
                type="text"
                className={FD.inputtext}
                value={feesCollectionLink}
                placeholder="Enter Fees Collection Link"
                onChange={(e) => setFeesCollectionLink(e.target.value)}
              />
            </label>
            <br />
            <br />
            {schoolData.fees_collection_link === "" ? (
              <button
                onClick={updateFeesCollectionLink}
                className={FD.savebutton}
              >
                Save
              </button>
            ) : (
              <></>
            )}
            <h4 className={Ad.errormessage}>{error}</h4>{" "}
          </div>
        </Box>
      </Modal>
      <Modal
        open={openQRModal}
        onClose={handleQRMClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "80vh",
              overflowY: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p>Fees QR Code</p>
              {schoolData.fees_collection_QR !== "" ? (
                <img
                  src={schoolData.fees_collection_QR}
                  style={{ width: "50%", height: "auto" }}
                  alt="QR code"
                />
              ) : (
                "No QR Code Image Available"
              )}
              {/* <button
                onClick={deleteFeesCollectionQR}
                className={FD.deletebutton}
              >
                Delete QR Code
              </button> */}
            </div>
            {/* <a
              href={schoolData.fees_collection_QR}
              target="_blank"
              rel="noopener noreferrer"
            >
              <label
                style={{
                  padding: "10px",
                  width: "100%",
                  cursor: "pointer",
                  border: "1px solid",
                  display: "inline-block",
                  backgroundColor: "#f0f0f0",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                {schoolData.fees_collection_QR !== null
                  ? "View QR Image"
                  : "No QR Image Available"}
              </label>
            </a> */}

            <br />
            {schoolData.fees_collection_QR === "" ? (
              <div>
                <label>
                  Update Fees Collection QR Image
                  <input
                    type="file"
                    // className={FD.inputtext}
                    name="fees_collection_QR"
                    accept="image/*"
                    style={{
                      marginTop: "10px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                    onChange={(e) => setFeesCollectionQR(e.target.files[0])}
                  />
                </label>
                <br />
                <button
                  onClick={updateFeesCollectionQR}
                  className={FD.savebutton}
                >
                  Save
                </button>
                <h4 className={Ad.errormessage}>{error}</h4>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Box>
      </Modal>
      <div className={FD.headingholder}>
        <div className={FD.lefthead}>
          <img
            src={feesIcon}
            alt="fees template"
            className={FD.noticeboardicon}
          />
          <h3 className={FD.heading}>Fees Collection Methods</h3>
        </div>
      </div>
      {spin === false ? (
        <div className={FD.TemplateCardsDiv}>
          {/* <div className={FD.addstudentcard} onClick={handleOpen}>
            <PlusIcon />
            <h3 className={FD.heading}> Create New Template</h3>
          </div> */}
          <div className={FD.addstudentcard} onClick={handleLinkMOpen}>
            <h3 className={FD.heading}>
              Fees Collection{" "}
              <span style={{ textDecoration: "underline" }}>Link</span>
            </h3>
          </div>

          <div className={FD.addstudentcard} onClick={handleQRMOpen}>
            <h3 className={FD.heading}>
              Fees Collection{" "}
              <span style={{ textDecoration: "underline" }}>QR Code</span>
            </h3>
          </div>

          {/* {templates.length > 0 &&
            templates.map((item, index) => (
              <div
                className={FD.addstudentcard}
                onClick={() => handleTemplate(item)}
                key={index}
              >
                <h3 className={FD.heading}>{item.template_name}</h3>
              </div>
            ))} */}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width="100%" height={500} />
        </div>
      )}
    </div>
  );
}
